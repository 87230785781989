import React, { useState } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import ReactPlayer from "react-player"

const BlackFridayHomeVideoBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  color: #fff;
  padding: 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ButtonTitle = styled.span`
  display: none;
`

const LeftSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  background: #141211;

  @media (max-width: 600px) {
    display: none;
  }
`

const BlackFridayTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #fff;
  max-width: 600px;
  padding: 10px 30px 30px;
  z-index: 1;

  @media (max-width: 992px) {
    padding: 0px 20px 20px;
  }
`

const BlackFridayLogo = styled.img`
  width: 100%;
  margin: 0px;
  object-fit: contain;
`

const BlackFridayText = styled.div`
  font-size: 30px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  letter-spacing: 1px;

  > span:nth-of-type(1) {
    font-size: 80px;
    font-family: "BebasNeueBold";
    font-weight: 700;
    line-height: 1.1;
    margin-right: 10px;
    color: #fff;
    text-shadow: 0.17px 0px 2.71px #f9a326;
    filter: drop-shadow(0px 0.35px 1.78px #f9a326);

    @media (max-width: 992px) {
      font-size: 60px;
    }

    @media (max-width: 767px) {
      font-size: 52px;
      margin-right: 7px;
    }
  }

  span:nth-of-type(2) {
    color: #fddeb0;

    span {
      display: block;
      fonr-weight: bold;
      color: #fff;
      text-shadow: 0.5px 0px 4px 0px #f9a326;
      filter: drop-shadow(0px 1.74px 8.7px #f9a326);
    }
  }

  @media (max-width: 992px) {
    font-size: 24px;
  }

  @media (max-width: 767px) {
    font-size: 18px;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  color: #262626;
  padding: 0;
`

const AbsoluteButton = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-indent: -9999px;
  z-index: 2;
`

const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;

  div {
    position: relative;
    display: flex;
  }

  iframe,
  video {
    width: 130% !important;
    height: 130% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    margin: 0px;
    border: 0px;

    @media (max-width: 1430px) {
      width: 150% !important;
      height: 150% !important;
    }

    @media (max-width: 720px) {
      width: 200% !important;
      height: 200% !important;
    }

    @media (max-width: 600px) {
      width: 100% !important;
      height: 100% !important;
      position: relative;
      top: 0px;
      left: 0px;
      transform: translate(0px, 0px);
    }
  }
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: cover;
  position: absolute;
  z-index: ${({ loading }) => (loading === "image" ? "2" : "1")};
  aspect-ratio: 16/9;
`

const TopSection = styled.div`
  display: flex;
  background: #e5e5e5;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #fff;
`

const Button = styled(Link)`
  background: #262626;
  background: linear-gradient(
    61.74deg,
    #ff7881 -4.05%,
    #ffab61 52.44%,
    #ffe775 91.92%
  );
  border-radius: 6px;
  padding: 15px 20px;
  color: #fff;
  color: #000;
  font-family: "Museo";
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  min-width: 160px;
  text-align: center;
  margin-top: 35px;
  border-radius: 25px 0px;
  position: relative;

  &:before {
    // content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: linear-gradient(
      61.74deg,
      #ff7881 -4.05%,
      #ffab61 52.44%,
      #ffe775 91.92%
    );
    border-radius: 25px 0;
    z-index: -1;
  }

  :hover {
    color: #fff;
  }

  @media (max-width: 992px) {
    margin-top: 25px;
  }
`

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 0px auto;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding: 30px;
  z-index: 1;

  @media (max-width: 1600px) {
    padding: 20px;
  }
`

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

const MedicaleImage = styled.img`
  max-width: 65px;
  height: 100%;
  max-height: 65px;
  width: 100%;
  margin: 0px 15px 0px 0px;

  @media (max-width: 600px) {
    max-width: 50px;
    max-height: 50px;
  }
`

const InnovationImage = styled.img`
  max-width: 65px;
  max-height: 65px;
  height: 100%;
  width: 100%;
  margin: 0px;

  @media (max-width: 600px) {
    max-width: 50px;
    max-height: 50px;
  }
`

export const BlackFridayHomeVideoBanner = ({ intl, data }) => {
  const [videoLoading, setVideoloading] = useState(true)

  const handleLoading = () => {
    setVideoloading(false)
  }
  return (
    data && (
      <BlackFridayHomeVideoBannerWrapper>
        <LeftSection className="HomeVideoBanner">
          {data.video_section_text.button_text &&
            data.video_section_text.button_url && (
              <AbsoluteButton to="/shop/lyneup-test/">
                <ButtonTitle>{data.video_section_text.button_text}</ButtonTitle>
              </AbsoluteButton>
            )}
          <BlackFridayTextWrapper>
            <BlackFridayLogo src="https://static.percko.com/uploads/1a8adc35-2d37-4b28-9332-99fa4ea7fb0f.gif" />
            <BlackFridayText
              dangerouslySetInnerHTML={{
                __html:
                  "<span>£15 OFF</span><span><span>one</span> PERCKO top</span>",
              }}
            />
            <BlackFridayText
              dangerouslySetInnerHTML={{
                __html:
                  "<span>£50 OFF</span><span><span>two</span> PERCKO tops</span>",
              }}
            />
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <Button to="/shop/lyneup-test/">{"Shop now >"}</Button>
              )}
          </BlackFridayTextWrapper>
        </LeftSection>
        <RightSection>
          <TopSection>
            {data.video_section_text.button_text &&
              data.video_section_text.button_url && (
                <AbsoluteButton to={data.video_section_text.button_url}>
                  {data.video_section_text.button_text}
                </AbsoluteButton>
              )}
            <VideoWrapper>
              <BannerImage
                src="https://static.percko.com/uploads/50d9167b-800d-4df0-9d44-cd786e28a4d4.jpg"
                loading={videoLoading ? "image" : "video"}
              />
              <ReactPlayer
                url="https://www.percko.com/videos/percko.com/gbp-en/home-page/home_page_top_black_friday_978x550_gbp.mp4"
                playing={true}
                controls={false}
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                playsinline={true}
                playsInline
                onPlay={() => {
                  handleLoading()
                }}
              />
            </VideoWrapper>
            <BottomWrapper>
              {data.video_section_text.medical_image &&
                data.video_section_text.innovation_image && (
                  <IconWrapper>
                    <MedicaleImage
                      src={data.video_section_text.medical_image}
                    />
                    <InnovationImage
                      src={data.video_section_text.innovation_image}
                    />
                  </IconWrapper>
                )}
            </BottomWrapper>
          </TopSection>
        </RightSection>
      </BlackFridayHomeVideoBannerWrapper>
    )
  )
}

export default injectIntl(BlackFridayHomeVideoBanner)
